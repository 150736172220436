/* eslint-disable react/no-danger */
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { Section, TitleGrid, Wrapper } from './TextContent.style';

/**
 * TextContent.
 */
const TextContent = ({ sections, headline, wideTextBlock = false }) => {
  const title = headline || null;

  return (
    <Wrapper container columnSpacing={4} wideTextBlock={wideTextBlock}>
      {!wideTextBlock && (
        <TitleGrid item xs={12} md={4}>
          {title}
        </TitleGrid>
      )}

      <Grid item xs={12} md={wideTextBlock ? 12 : 8}>
        {sections.map((section, index) => (
          <Section
            key={index}
            index={index}
            sectionsLength={sections.length}
            wideTextBlock={wideTextBlock}>
            {section}
          </Section>
        ))}
      </Grid>
    </Wrapper>
  );
};

TextContent.propTypes = {
  /** Content to be displayed. */
  sections: PropTypes.array.isRequired,
  /** Headline of the section */
  headline: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]),
  wideTextBlock: PropTypes.bool,
};

TextContent.defaultProps = {
  headline: '',
};

export default TextContent;
